/* KegiatanSection.css */
.prestasi-section {
  background-color: rgb(250, 250, 250);
}
.title-prestasi{
  color: black !important;
}

/* Media Queries untuk responsivitas */
@media (max-width: 768px) {
  .prestasi-section {
    padding: 40px 20px;
  }
  .ant-card {
    margin-bottom: 20px; /* Menambahkan margin bottom untuk kartu di mobile */
  }
  .title-tab-prestasi{
    display: none;
  }
}
@media (min-width: 768px) {
  .prestasi-section {
    padding: 40px 80px; /* Padding lebih besar untuk tablet dan desktop */
  }
  .title-prestasi{
    display: none;
  }
}

@media (min-width: 1200px) {
  .prestasi-section {
    padding: 40px 160px; /* Padding lebih besar untuk layar besar */
  }
}
