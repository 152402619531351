.aplikasi-section {
  align-items: center; /* Memastikan konten berada di tengah secara vertikal */
  height: 43vh; /* Memastikan tinggi penuh */
  background: rgba(255, 255, 255, 0.83) url('/public/images/bg-aplikasi-section.jpg') no-repeat center center; /* Warna latar belakang dengan opacity */
  background-size: cover; /* Memastikan gambar menutupi area */
  background-color: #fff;
  background-position: right;
  color: #000; /* Warna teks agar kontras dengan latar belakang */
}

.aplikasi-section a:hover {
  text-decoration: none;
  color: #000;
}

/* Media Queries untuk responsivitas */
@media (max-width: 768px) {
  .aplikasi-section {
    height: auto;
    text-align: center; /* Rata tengah untuk teks */
    padding: 32px 16px;
  }
}

@media (min-width: 768px) {
  .aplikasi-section {
    padding: 32px 160px;
  }
}
