/* KegiatanSection.css */
.artikel-section {
  padding: 40px 20px;
  align-items: center; /* Memastikan konten berada di tengah secara vertikal */
  background: rgba(255, 255, 255, 0.83) url('/public/images/bg-artikel-section.jpg') no-repeat center center; /* Warna latar belakang dengan opacity */
  background-size: cover; /* Memastikan gambar menutupi area */
  background-color: #fff;
  background-position: right;
  color: #000; /* Warna teks agar kontras dengan latar belakang */
}

.artikel-section h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 32px;
}

.artikel-section .feature-header{
  color: #000;
}

/* Media Queries untuk responsivitas */
@media (max-width: 768px) {
  .artikel-section h2 {
    font-size: 28px; /* Ukuran font lebih kecil di mobile */
  }
}

@media (min-width: 768px) {
  .artikel-section {
    padding: 40px 80px; /* Padding lebih besar untuk tablet dan desktop */
  }
}

@media (min-width: 1200px) {
  .artikel-section {
    padding: 40px 160px; /* Padding lebih besar untuk layar besar */
  }
}
