.hero-section {
  display: flex;
  justify-content: flex-start; /* Mengatur posisi ke kiri */
  align-items: center; /* Memastikan konten berada di tengah secara vertikal */
  height: 100vh; /* Memastikan tinggi penuh */
  background: rgba(255, 255, 255, 0.83) url('/public/images/bg-hero.jpg') no-repeat center center; /* Warna latar belakang dengan opacity */
  background-size: contain; /* Memastikan gambar menutupi area */
  background-color: #fff;
  background-position: right;
  padding: 20px; /* Ruang di sekitar konten */
  color: #000; /* Warna teks agar kontras dengan latar belakang */
}

.info-section {
  max-width: 550px; /* Batasi lebar informasi */
  background-color: rgba(255, 255, 255, 0.5); /* Latar belakang semi-transparan untuk teks agar lebih terlihat */
  border-radius: 10px; /* Sudut melengkung */
}

.hero-title{
  font-size: 48px !important;
  font-weight: bold !important;
  margin-top: 16px !important;
}

.announcement-box {
  margin-top: 30px; /* Ruang atas untuk pemisahan */
  padding: 20px; /* Ruang di dalam box */
  background-color: rgba(255, 255, 255, 0.9); /* Latar belakang putih semi-transparan */
  border-radius: 10px; /* Sudut melengkung */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Bayangan untuk memberi efek kedalaman */
  border-left: 3px solid #10b582;
}
.announcement-text {
  width: 100%;
}

.announcement-content {
  display: flex; /* Mengatur isi pengumuman dalam satu baris */
  align-items: center; /* Memastikan ikon dan teks berada di tengah secara vertikal */
  text-align: left;
}

.announcement-content h4 {
  color: #10b582;
}

.announcement-icon {
  font-size: 24px; /* Ukuran ikon */
  color: #10b582; /* Warna ikon */
  margin-right: 10px; /* Ruang di kanan ikon */
  background-color: #fff1f0;
  border-radius: 50%;
  padding: 12px;
}

.announcement-box h3 {
  font-size: 24px; /* Ukuran font untuk judul pengumuman */
  margin: 0; /* Menghapus margin default */
  margin-bottom: 10px; /* Ruang bawah untuk pemisahan */
}

.buttons {
  display: inline-flex; /* Mengatur tombol dalam satu baris */
  gap: 8px; /* Ruang antara tombol */
  margin-top: 20px; /* Ruang atas untuk pemisahan */
  justify-content: flex-end;
}

.custom-button {
  flex: 1; /* Membuat tombol mengisi ruang yang tersedia */
}

/* Media Queries untuk responsivitas */
@media (max-width: 768px) {
  .hero-section {
    height: auto;
    margin-top: 32px;
    text-align: center; /* Rata tengah untuk teks */
    padding: 32 16px;
  }

  .info-section {
    padding: 20px 0; /* Mengurangi padding pada layar kecil */
    max-width: 100%; /* Memastikan info-section tidak terlalu lebar di perangkat kecil */
    background-color: rgba(255, 255, 255, 0.836); /* Latar belakang semi-transparan untuk teks agar lebih terlihat */
  }

  .hero-title{
    font-size: 32px !important;
  }
  .hero-description{
    font-size: 12px !important;
  }
  
  .announcement-content{
    overflow-x: auto;
    padding: 0;
  }

  .form-alumni-desktop{
    display: none;
  }
}


@media (min-width: 768px) {
  .hero-section {
    padding: 0 160px;
  }
  .form-alumni-mobile{
    display: none;
  }

}