.close-preview{
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 1;
    background: #ffffff;
    border-radius: 50%;
    color: #070707;
    padding: 4px 8px;
    box-shadow: 1px 3px 5px -2px rgba(0, 0, 0, 0.39);
    -webkit-box-shadow: 2px 4px 8px -2px rgba(0, 0, 0, 0.39);
    -moz-box-shadow: 1px 3px 5px -2px rgba(0, 0, 0, 0.39);
}
.close-preview-guestbook{
    position: absolute;
    top: 50px;
    right: 10px;
    z-index: 1;
    background: #ffffff;
    border-radius: 50%;
    color: #070707;
    padding: 4px 8px;
    box-shadow: 1px 3px 5px -2px rgba(0, 0, 0, 0.39);
    -webkit-box-shadow: 2px 4px 8px -2px rgba(0, 0, 0, 0.39);
    -moz-box-shadow: 1px 3px 5px -2px rgba(0, 0, 0, 0.39);
}