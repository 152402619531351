/* KegiatanSection.css */
.gerbang-section {
  padding: 0px 20px;
  background-color: #fff;
}

.gerbang-section-box {
  background: linear-gradient(180deg, rgba(16, 181, 130, .99) 50%, rgba(16, 181, 130, 0) 137.91%);
  background-image: url('/public/images/img-siswasiswi.png'), url('/public/images/bg-gerbang.jpg');
  background-position: 100% 100%, 0 0;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, cover;
  border-radius: 8px;
  height: 244px;
  padding: 24px;
}

.gerbang-section h2 {
  color: #fff;
  font-family: Work Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  max-width: 530px;
}

/* Media Queries untuk responsivitas */
@media (max-width: 768px) {
  .gerbang-section h2 {
    font-size: 28px; /* Ukuran font lebih kecil di mobile */
  }
}

@media (min-width: 768px) {
  .gerbang-section {
    padding: 0px 80px; /* Padding lebih besar untuk tablet dan desktop */
  }
}

@media (min-width: 1200px) {
  .gerbang-section {
    padding: 40px 160px; /* Padding lebih besar untuk layar besar */
  }
}
