/* KegiatanSection.css */
.menubottom-section {
  padding: 0px 20px;
  background-color: #fff;
}

.logo-bottom {
  background-image: url('/public/images/logo-man.png');
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  margin: 0 auto; 
}

.menubottom-section h2{
  text-align: center;
  margin: 16px;
}

.menubottom-section p{
  text-align: center;
  margin: 16px;
  font-size: 12px;
  color:#999;
}

/* Media Queries untuk responsivitas */
@media (max-width: 768px) {
  
}

@media (min-width: 768px) {
  .menubottom-section {
    padding: 0px 80px; /* Padding lebih besar untuk tablet dan desktop */
  }
}

@media (min-width: 1200px) {
  .menubottom-section {
    padding: 40px 160px; /* Padding lebih besar untuk layar besar */
  }
}
