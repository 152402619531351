/* KegiatanSection.css */
.kegiatan-section {
  padding: 40px 20px;
  background-color: #fff;
}

.kegiatan-section h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 32px;
}

.kegiatan-section .feature-header{
  color: #000;
}

/* Media Queries untuk responsivitas */
@media (max-width: 768px) {
  .kegiatan-section h2 {
    font-size: 28px; /* Ukuran font lebih kecil di mobile */
  }
}

@media (min-width: 768px) {
  .kegiatan-section {
    padding: 40px 80px; /* Padding lebih besar untuk tablet dan desktop */
  }
}

@media (min-width: 1200px) {
  .kegiatan-section {
    padding: 40px 160px; /* Padding lebih besar untuk layar besar */
  }
}
