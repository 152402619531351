/* src/components/Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 160px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 1px 2px #f0f1f2;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo img {
  height: 50px;
  margin-right: 15px;
}

.header-title {
  margin: 0;
  font-size: 18px;
}

.header-description {
  font-size: 11px;
}

.header-menu-mobile {
  display: block;
}

.header-drawer .ant-drawer-body {
  padding: 0;
}

.header-text {
  line-height: 1em
}

.header-title {
  margin: 0 !important;
}

.header-description {
  line-height: 1em;
  color: #999;
}

/* Media Queries untuk responsivitas */
@media (max-width: 768px) {
  .header {
    padding: 0 16px;
  }
}
