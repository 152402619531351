/* src/App.css */

html, body, #root {
  padding: 0;
  height: 100%;
  font-family: 'Work Sans', sans-serif;
  background-color: #ffffff;
  scroll-behavior: smooth; /* Efek smooth scroll pada HTML */
}

a {
  color: inherit;
  text-decoration: none;
}

h2 {
  font-family: 'Work Sans', sans-serif;
}

.app-container {
  background-color: #ffffff;
}

.ant-btn {
  border-radius: 2px !important;
}

.ant-btn:hover {
  border-color: #10b582 !important;
  color: #10b582 !important;
}

.ant-btn-primary {
  background: #10b582 !important;
  border-color: #0ba475 !important;
  color: #ffffff !important;
}

.ant-btn-primary:hover {
  background: #0ba475 !important;
  border-color: #0ba475 !important;
  color: #ffffff !important;
}


.feature-header{
  text-align: center;
  color: #fff;
  margin-bottom: 40px;
}
.feature-header h2{
  font-size: 30px;
}

.divider-line{
  border-top: 2px solid rgb(255, 192, 105);
  max-width: 100px;
  margin: 16px auto;
}

.feature-card {
  background-color: #fff; /* Warna latar belakang kartu */
  border-radius: 10px; /* Sudut melengkung */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Bayangan untuk kedalaman */
  text-align: center; /* Rata tengah untuk konten */
  padding: 16px; /* Ruang di dalam kartu */
}

.feature-image {
  width: 100%; /* Lebar penuh untuk gambar */
  height: auto; /* Tinggi otomatis untuk mempertahankan aspek rasio */
  max-width: 80px; /* Maksimal lebar gambar untuk menghindari terlalu besar */
  margin: 0 auto 10px; /* Margin bawah untuk pemisah antara gambar dan teks */
}

/* Gaya tambahan untuk judul dan deskripsi */
.feature-card h3 {
  font-size: 20px; /* Ukuran font untuk judul */
  margin: 10px 0; /* Margin untuk judul */
}

.feature-card p {
  font-size: 12px; /* Ukuran font untuk deskripsi */
  color: #999; /* Warna teks untuk deskripsi */
  display: none;
}


/* Efek zoom hanya pada gambar */
.ant-card {
  overflow: hidden; /* Menyembunyikan bagian yang melampaui */
}

.ant-card-cover img{
  max-height: 160px;
}

.ant-card-body{
  background-color: #fff !important;
}

.ant-card img {
  transition: transform 0.3s; /* Efek zoom pada gambar */
}

.ant-card:hover img {
  transform: scale(1.2); /* Zoom in pada gambar saat hover */
}

.modal-artikel .ant-modal-header{
  padding: 8px !important;
}
.modal-artikel .ant-modal-content{
  padding: 8px 0 !important;
}
.modal-artikel .modal-description{
  padding: 16px !important;
  font-size: 12px !important;
  text-align: justify !important;
  color: #999 !important;
}

.loadmore-button{
  margin: 0 auto !important;
}

/* Media Queries untuk responsivitas */
@media (max-width: 768px) {
  .feature-card {
    min-height: 22vh;
  }

  .ant-card {
    margin-bottom: 20px; /* Menambahkan margin bottom untuk kartu di mobile */
  }
}

@media (min-width: 768px) {
  .feature-card {
    min-height: 20vh;
  }
}